@import "../../common.scss";

/**Header**/
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 55px 0 10px;
  transition: background 0.3s ease-out;
  -webkit-transition: background 0.3s ease-out;
  // background: linear-gradient(96.13deg, #04bc2c 4.19%, #01bfc2 97.55%);
  z-index: 111;
  @media (max-width: 991px) {
    padding: 0;
  }
}

.main_logo {
  img {
    @media (max-width: 575px) {
      width: 190px;
    }
  }
  z-index: 999999999999999;
}

.navbar_toggler {
  border: 0;
  padding: 0;
  background: none;
  box-shadow: none !important;
  font-size: 1.25em;
  @media (min-width: 992px) {
    display: none;
  }
}

#main_menu {
  @media (max-width: 991px) {
    display: none;
  }
}
.main_menu {
  @media (max-width: 991px) {
    background: #01bfb5;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  li {
    &:not(:last-child) {
      @media (max-width: 991px) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
    a {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      padding: 0px 15px;
      text-transform: uppercase;
      text-decoration: none;
      @media (max-width: 991px) {
        padding: 14px 0;
        display: block;
      }
    }
    &.active {
      a {
        font-weight: 700;
      }
    }
  }
}

.animated_pics {
  pointer-events: none;
  position: absolute;
  top: -30px;
  @media (min-width: 768px) {
    left: -80%;
  }
  @media (max-width: 767px) {
    left: -20%;
    width: 500px;
  }
  @media (max-width: 375px) {
    margin-top: 70px;
  }
}

.blur_logo {
  opacity: 0.1;
  position: absolute;
  top: 5%;
  @media (min-width: 575px) {
    left: -7%;
  }
  transform: scale(0.9);
  @media (max-width: 575px) {
    max-width: 100%;
    left: 0;
  }
  z-index: 1111;
}

.blur_logo_2 {
  opacity: 0.1;
  position: absolute;
  top: 5%;
  @media (min-width: 575px) {
    left: -7%;
  }
  transform: scale(0.7);
  -webkit-animation: ripple1 3s linear infinite;
  animation: ripple1 3s linear infinite;
  @media (max-width: 575px) {
    max-width: 100%;
    left: 0;
  }
}

.rht_animated_pics {
  position: absolute;
  top: -30px;
  left: 45%;
  max-width: 300px;
  @media (max-width: 767px) {
    top: 20%;
  }
  .b_logo1 {
    opacity: 0.1;
    position: absolute;
    transform: scale(0.9);
    max-width: 380px;
  }

  .b_logo2 {
    opacity: 0.1;
    position: absolute;
    transform: scale(1);
    max-width: 380px;
    -webkit-animation: ripple1 3s linear infinite;
    animation: ripple1 3s linear infinite;
    @media (max-width: 575px) {
      max-width: 100%;
      left: 0;
    }
  }
}
// Banner
.banner_sec {
  background: linear-gradient(96.13deg, #04bc2c 4.19%, #01c0cc 97.55%);
  padding: 160px 0 122px;
  min-height: 100%;
  overflow: hidden;
  @media (max-width: 991px) {
    padding-top: 100px;
  }
  @media (max-width: 575px) {
    padding-top: 70px;
  }
  .banner_wrap {
    // margin: 0 50px 0 50px;
    .banner_summary {
      position: relative;
      color: #fff;
      padding-top: 50px;
      padding-right: 10%;
      z-index: 1;
      @media (max-width: 991px) {
        padding-right: 0;
        text-align: center;
      }
      @media (max-width: 991px) {
        padding-top: 0;
      }
      &:before {
        content: "";
        pointer-events: none;
        // background: url(./images/banner_sum_shade.png) no-repeat;
        background-size: 80%;
        padding: 50%;
        position: absolute;
        left: -10%;
        top: 0;
        z-index: -1;
        -webkit-animation: ripple1 3s linear infinite;
        animation: ripple1 3s linear infinite;
        @media (max-width: 575px) {
          background-size: 100%;
          left: 0;
          margin-top: 40px;
        }
      }
      h2 {
        color: inherit;
        margin: 40px 0 30px 0;
        text-align: left;
        @media (min-width: 1280px) {
          font-size: 2.2rem;
        }
        @media (max-width: 1280px) {
          font-size: 1.8rem;
          max-width: 100%;
        }
        @media (max-width: 767px) {
          text-align: center;
          font-size: 1.7rem;
          max-width: 100%;
        }
        font-weight: 500;
      }
      p {
        color: inherit;
        font-size: 18px;
        text-align: left;
        @media (max-width: 767px) {
          text-align: center;
          margin: auto;
          max-width: 80%;
        }
        @media (max-width: 575px) {
          text-align: center;
          margin: auto;
          max-width: 90%;
        }
      }
    }
  }
}
.mob_pic {
  position: relative;
  z-index: 1;
  &:before {
    content: "";
    pointer-events: none;
    background: url(../../../images/banner_sum_shade.png) no-repeat;
    background-size: 100%;
    padding: 70% 50%;
    position: absolute;
    right: -50%;
    top: -9%;
    z-index: -1;
  }
}

.btn_apps {
  margin-top: 40px;
  @media (max-width: 767px) {
    margin-top: 40px;
  }
  img {
    @media (max-width: 1200px) {
      width: 180px;
    }
  }
  img {
    @media (max-width: 992px) {
      width: 140px;
    }
  }
  img {
    @media (max-width: 575px) {
      width: 120px;
    }
  }
  z-index: 9999999999999999;

  a {
    margin-top: 15px;
    margin-right: 25px;
    @media (max-width: 575px) {
      margin-right: 10px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.navbar_toggler_icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat 50%;
  background-size: 100% 100%;
}

.navbar_toggler_icon i {
  color: #fff;
  display: flex;
  align-items: center;
  height: 100%;
}

.ban_btm {
  @media (max-width: 767px) {
    margin: 20px 0 0;
    justify-content: center;
    text-align: center;
  }
  p {
    margin-top: 20px;
    margin-bottom: 0;
    margin-left: 15px;
    font-weight: 500;
    @media (max-width: 992px) {
      margin-left: 0;
      margin-top: 10px;
    }
    span {
      color: #fff;
    }
    a {
      color: #fff;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
// Mobile Animation
.home_right {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  @media (max-width: 1199px) {
    float: right;
    justify-content: flex-end;
  }
  @media (max-width: 767px) {
    float: none;
    justify-content: center;
    max-width: 330px;
    margin: 30px auto 30px;
  }
  @media (max-width: 575px) {
    width: 290px;
  }
}

.mobileslid {
  text-align: center;
  position: relative;
  z-index: 1;
  img {
    @media (max-width: 1366px) {
      width: 74%;
    }
    @media (max-width: 1199px) {
      width: 250px;
      margin-bottom: 0;
    }
  }
}
.profilemsg {
  position: absolute;
  top: 9%;
  right: 30px;
  z-index: 11;
  @media (max-width: 1366px) {
    transform: scale(0.7);
    top: 13%;
    right: 40px;
  }
  @media (max-width: 1200px) {
    transform: scale(0.7);
    top: 8%;
    right: -100px;
  }
  @media (max-width: 992px) {
    transform: scale(0.7);
    right: -90px;
  }
  @media (max-width: 768px) {
    transform: scale(0.7);
    right: -60px;
    top: 11%;
  }
  @media (max-width: 575px) {
    transform: scale(0.7);
    right: -80px;
  }
  img {
    animation: movebounce 2.1s linear infinite;
  }
}
.awesome {
  position: absolute;
  top: 52%;
  left: 80px;
  z-index: 11;
  transform: scale(0.8);
  @media (max-width: 1366px) {
    width: 220px;
    left: 140px;
    top: 53%;
  }
  @media (max-width: 1200px) {
    left: -15px;
  }
  @media (max-width: 992px) {
    width: 220px;
    left: -15px;
  }
  @media (max-width: 768px) {
    top: 53%;
    width: 220px;
    left: 20px;
  }
  @media (max-width: 575px) {
    left: 0px;
  }
  img {
    animation: movebounce 2.1s linear infinite;
  }
}
.profileone {
  position: absolute;
  bottom: 17%;
  right: 0px;
  z-index: 11;
  @media (max-width: 1366px) {
    bottom: 10%;
    transform: scale(0.8);
    right: 40px;
  }
  @media (max-width: 1200px) {
    transform: scale(0.7);
    right: -90px;
  }
  @media (max-width: 992px) {
    right: -90px;
  }
  @media (max-width: 768px) {
    right: -50px;
    bottom: 13%;
  }
  @media (max-width: 575px) {
    right: -70px;
  }
  &::before {
    animation: 2.5s anim-effct-slide 0s linear infinite;
    box-shadow: 0 0 0 80px #e8e8e8;
    position: absolute;
    content: "";
    width: 110px;
    height: 110px;
    top: 26%;
    left: 26%;
    border-radius: 50%;
    z-index: 0;
  }
}

.profiletwo {
  position: absolute;
  left: 0px;
  top: 5%;
  z-index: 11;
  @media (max-width: 1366px) {
    top: 1%;
    transform: scale(0.8);
    left: 40px;
  }
  @media (max-width: 1200px) {
    transform: scale(0.7);
    left: -100px;
  }
  @media (max-width: 992px) {
    left: -100px;
  }
  @media (max-width: 768px) {
    top: 5%;
    left: -60px;
  }
  @media (max-width: 575px) {
    left: -80px;
  }
  &::before {
    content: "";
    animation: 2.5s anim-effct-slide1 0s linear infinite;
    box-shadow: 0 0 0 80px hsl(0deg 0% 91% / 10%);
    position: absolute;
    width: 110px;
    height: 110px;
    top: 26%;
    left: 26%;
    border-radius: 50%;
    z-index: 0;
  }
}

.emoji {
  filter: drop-shadow(0px 0px 5px #ffffff);
  position: absolute;
  left: 6%;
  bottom: 8%;
  z-index: 11;
  width: 150px;
  height: 150px;
  transform: scale(0.7);
  @media (max-width: 1366px) {
    width: 90px;
    height: 90px;
    left: 20%;
    bottom: 11%;
  }
  @media (max-width: 1200px) {
    width: 80px;
    height: 80px;
    left: -11%;
    bottom: 13%;
  }
  @media (max-width: 992px) {
    width: 70px;
    height: 70px;
    left: -10%;
  }
  @media (max-width: 768px) {
    left: 6%;
    bottom: 16%;
  }
  @media (max-width: 575px) {
    left: 0%;
  }
  img {
    animation: moveleftbounce 2.1s linear infinite;
  }
}

@keyframes anim-effct-slide {
  0% {
    box-shadow: 0 0 0 8px #e0e0e0;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 15px rgba(224, 224, 224, 0.6);
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
    opacity: 0;
  }
}

@keyframes anim-effct-slide1 {
  0% {
    box-shadow: 0 0 0 8px rgba(232, 232, 232, 0.1);
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 15px rgba(224, 224, 224, 0.6);
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
    opacity: 0;
  }
}

@keyframes movebounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes moveleftbounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(8px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes ripple1 {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  33% {
    transform: scale(0.95);
    opacity: 0.05;
  }
  66% {
    transform: scale(1);
    opacity: 0.1;
  }
  100% {
    transform: scale(1.05);
    opacity: 0;
  }
}

.ban_btm {
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  p {
    font-size: 16px;
  }
}
