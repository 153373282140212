@import "../../common.scss";

.banner_sec {
  padding: 160px 0 120px;
  min-height: 100%;
  overflow: hidden;
  @media (max-width: 991px) {
    padding-top: 120px;
  }
  @media (max-width: 575px) {
    padding-top: 90px;
  }
}

.aboutus_contain {
  line-height: 25px;
  border-radius: 20px;
  padding: 30px;
  background: #f9f9f9;
  h3 {
    padding-top: 15px;
    font-size: 20px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
  }
  p {
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
  }
  @media (max-width: 992px) {
    margin-top: 20px;
  }
}

.aboutus_contain ul li {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

.carosel_div {
  border-radius: 20px;
  height: 100%;
  width: 100%;
  img {
    border-radius: 20px;
    width: 100%;
  }
}

.aboutUs_carousel_div {
  border-radius: 20px;
  height: 100%;
  width: 100%;
}

.aboutus_img {
  object-fit: cover;
  border-radius: 20px;
  height: 500px;
  width: 100%;
}
