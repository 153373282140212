@import "../../common.scss";

.banner_sec {
  padding: 200px 0 260px;
  min-height: 100%;
  overflow: hidden;
  i {
    color: #24b663;
    font-size: 9.25em;
    @media (max-width: 992px) {
      font-size: 8.25em;
    }
    @media (max-width: 768px) {
      font-size: 7.25em;
    }
    @media (max-width: 575px) {
      font-size: 6.25em;
    }
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.site_title {
  font-size: 6.25em;
  font-weight: 700;
  @media (max-width: 992px) {
    font-size: 5.25em;
  }
  @media (max-width: 768px) {
    font-size: 4.25em;
  }
  @media (max-width: 575px) {
    font-size: 3.25em;
  }
}
