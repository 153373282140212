$white: #fff;
$black: #000;
$blue: #0c6084;

$warning: #d2042d;
$lightRed: #ff3f34;
$red-40: #d21a1a;
$red-60: #e65c5c;
$red-100: #fff2f2;

$grey: #808080;
$grey-30: #cccccc4d;
$grey-50: #cccccc80;
$grey-80: #cccccc;
$grey-90: #cccccce6;
$grey-100: #f6f6f6;

$darkBlue: #002737;
$darkBlue-30: #0076a8;
$darkBlue-50: #308fb6;
$vividBlue: #0fbcf9;
$paleBlue: #dbf4ff;

$yellow: #fec200;
$brightYellow: #fbd849;
$vividOrange: #ffa801;

$limeGreen: #05c46b;
$softLimeGreen: #67ec86;
