/**Header**/
@import "../../../shared/styles/index.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 55px 0 10px;
  transition: background 0.3s ease-out;
  -webkit-transition: background 0.3s ease-out;
  z-index: 111;
  @media (max-width: 991px) {
    padding: 0;
  }
  z-index: 9999;
}

.light_background {
  background: white;
  box-shadow: 1px 1px 12px 1px rgb(0 0 0 / 13%);
}
.dark_background {
  background: linear-gradient(96.13deg, #04bc2c 4.19%, #01bfc2 97.55%);
  box-shadow: 1px 1px 12px 1px rgb(0 0 0 / 13%);
}

.main_logo {
  img {
    @media (max-width: 575px) {
      width: 190px;
    }
  }
  @media (max-width: 575px) {
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
  }
}

.sub_logo_text {
  position: "absolute";
  bottom: -20px;
}

.navbar_brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  // margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar_toggler {
  border: 0;
  padding: 0;
  background: none;
  box-shadow: none !important;
  font-size: 1.25em;
  @media (min-width: 992px) {
    display: none;
  }
}

#main_menu {
  @media (max-width: 991px) {
    display: none;
  }
}
.main_menu {
  @media (max-width: 991px) {
    background: #01bfb5;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  li {
    &:not(:last-child) {
      @media (max-width: 991px) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
    a {
      font-size: 16px;
      font-weight: 400;
      padding: 0px 25px;
      text-transform: uppercase;
      text-decoration: none;
      @media (max-width: 1200px) {
        padding: 0px 10px;
      }
      @media (max-width: 991px) {
        padding: 14px 0;
        display: block;
      }
    }
    &.active {
      a {
        font-weight: 700;
      }
    }

    &.light_option {
      a {
        color: #04bc2c;
      }
    }

    &.dark_option {
      a {
        color: #fff;
      }
    }
  }
}

.badge {
  font-weight: 600;
  display: flex;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: red;
  position: absolute;
  right: -11px;
  top: -8px;
  border-radius: 10px;
  color: white;
  line-height: 20px;
  font-size: 14px;
}

.badge_alternative {
  font-weight: 600;
  display: flex;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: red;
  position: absolute;
  right: -11px;
  top: -8px;
  border-radius: 10px;
  color: white;
  line-height: 20px;
  font-size: 14px;
  // @media (max-width: 992px) {
  //   right: 40px;
  // }
  @media (max-width: 575px) {
    left: 12px;
    right: 0;
    top: -11px;
  }
}

.cart {
  position: relative;
  :hover {
    cursor: pointer;
  }
}

.cart_container {
  box-shadow: 1px 1px 12px 1px rgb(0 0 0 / 13%);
  display: flex;
  max-height: 700px;
  overflow: scroll;
}

.cart_alternative {
  position: absolute;
  width: 30px;
  @media (min-width: 992px) {
    display: none;
  }
  @media (max-width: 992px) {
    right: 40px;
  }
  @media (max-width: 575px) {
    left: 0;
    top: 20px;
  }

  :hover {
    cursor: pointer;
  }
}
