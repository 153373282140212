@import "../../../views/common.scss";
.cart_container {
  float: right;
  background: white;
  width: 320px;
  // position: relative;
  padding: 20px;
  .shopping_cart_header {
    display: flex;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 15px;
    justify-content: space-between;
    .shopping_cart_total {
      float: right;
    }
    .cancel {
      color: $limeGreen;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .shopping_cart_items {
    padding-top: 20px;
    padding-left: 0px;
    li {
      margin-bottom: 18px;
    }

    img {
      float: left;
      margin-right: 12px;
    }

    .item_name {
      display: block;
      padding-top: 10px;
      font-size: 14px;
      font-weight: 600;
    }

    .item_price {
      color: $limeGreen;
      margin-right: 8px;
    }
    .cartItem {
      display: flex;
      padding: 0;
      .cross {
        padding-right: 20px;
        padding-top: 15px;
        font-size: 24px;
      }
      .cross:hover {
        cursor: pointer;
      }
    }
  }
}

.shopping_cart {
  border-radius: 3px;
}

.tax_disclaimer {
  text-align: center;
  display: flex;
  font-size: 8px;
  font-weight: 600;
  align-self: center;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  min-height: 200px;
  background: white;
  width: 320px;
  position: relative;
  padding: 20px;
}

.cart_icon {
  color: #515783;
  font-size: 24px;
  margin-right: 7px;
  float: left;
}

.button {
  background: $limeGreen;
  color: white;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  display: block;
  border-radius: 3px;
  font-size: 20px;
  font-weight: 600;
  &:hover {
    background: lighten($limeGreen, 3%);
  }
  width: 100%;
  border: none;
}

.clearfix {
  list-style-type: none;
  flex: 1;
}
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.cart_image {
  width: 70px;
  height: 70px;
}

.item_quantity {
  padding: 10px;
}

.quantity_change {
  cursor: pointer;
  font-size: 22px;
  padding: 0 5px 0 5px;
}
.main_color_text {
  color: $limeGreen;
  font-weight: bold;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid $limeGreen;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
