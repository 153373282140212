@import "../../common.scss";

.banner_sec {
  padding: 160px 0 140px;
  @media (max-width: 768px) {
    padding: 160px 0 80px;
  }
  min-height: 100%;
  overflow: hidden;
  @media (max-width: 991px) {
    padding-top: 120px;
  }
  @media (max-width: 575px) {
    padding-top: 90px;
  }
}

.accordian_container {
  // padding:0px 25px!important;
  border-radius: 10px !important;
  position: static !important;
  box-shadow: none !important;
  border: 2px solid #c4c4c4;
  ul {
    margin: 0;
    li {
      font-size: 18px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
    }
  }
  @media (max-width: 575px) {
    padding: 0px 10px !important;
  }
}

.accordian_content {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  padding: 0 20px;
  ul {
    margin: 0;
    li {
      font-size: 12px;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
    }
  }
  ul > li::marker {
    font-size: 6px;
  }

  .table {
    ul {
      margin-bottom: 1em;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
  }
}

.accordian_icon {
  font-size: 36px;
  color: #000000;
}
