// @import "../shared/styles/index.scss";

.footer {
  background: linear-gradient(96.13deg, #04bc2c 4.19%, #01c0cc 97.55%);
  color: #000000;
  cursor: pointer;
  position: relative;
  @media (max-width: 575px) {
    text-align: center;
  }
  padding-bottom: 20px;
}

.social_icons {
  margin: 0;
  li {
    margin-right: 15px;
    a {
      img {
        width: 30px;
      }
    }
  }
}
.footer_links {
  h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: bolder;
    text-decoration: underline;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  ul li {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
    a {
      color: #000000;
    }
  }
}

.footer_logo {
  height: 40px;
  @media (max-width: 768px) {
    margin-top: 15px;
  }
  @media (max-width: 600px) {
    margin-top: 15px;
  }
}

.footer_span {
  font-weight: 100;
}

.footer_top {
  position: relative;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  margin: -30px auto !important;
  padding: 11px;
  background: #000000;
  z-index: 999;
  p {
    font-family: "Montserrat", sans-serif;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    font-size: 16px;
    @media (max-width: 1200px) {
      font-size: 14px;
    }
  }
  @media (max-width: 1200px) {
    margin: -57px auto !important;
  }
  @media (max-width: 1200px) {
    margin: -34px auto !important;
  }
  @media (max-width: 768px) {
    justify-content: center;
    flex-wrap: wrap;
  }
  @media (max-width: 600px) {
    flex-wrap: wrap;
    margin: -65px auto !important;
  }
}

.footer_width {
  padding-top: 65px;
  @media (max-width: 768px) {
    padding-top: 75px;
  }
}

.footer_bg {
  height: 180px;
  position: absolute;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: space-between;
  img {
    opacity: 0.1;
  }
  @media (max-width: 768px) {
    height: 40%;
  }
  @media (max-width: 600px) {
    height: 60%;
  }
  @media (max-width: 550px) {
    height: 70%;
  }
  top: 0;
  bottom: 0;
  pointer-events: none;
}

.footer_left_img {
  position: absolute;
  bottom: 75px;
  left: 0px;
  height: 308px;
  width: 352px;
  transform: scale(0.9);
  @media (max-width: 600px) {
    top: 62px;
    left: 18px;
  }
}

.footer_left_img_2 {
  position: absolute;
  bottom: 75px;
  left: 0px;
  height: 308px;
  width: 352px;
  transform: scale(0.7);
  -webkit-animation: ripple1 3s linear infinite;
  animation: ripple1 3s linear infinite;
  @media (max-width: 600px) {
    top: 62px;
    left: 18px;
  }
}

.footer_right_img {
  right: 163px;
  bottom: 58px;
  position: absolute;
  height: 150px;
  width: 171px;
  @media (max-width: 768px) {
    right: 88px;
  }
  transform: scale(0.9);
  @media (max-width: 600px) {
    bottom: 0px;
    right: 118px;
  }
}

.footer_right_img_2 {
  right: 163px;
  bottom: 58px;
  position: absolute;
  height: 150px;
  width: 171px;
  @media (max-width: 768px) {
    right: 88px;
  }
  transform: scale(0.7);
  -webkit-animation: ripple1 3s linear infinite;
  animation: ripple1 3s linear infinite;
  @media (max-width: 600px) {
    bottom: 0px;
    right: 118px;
  }
}

.footer_icons {
  display: flex;
  position: relative;
  @media (max-width: 1200px) {
    justify-content: center;
    align-items: center;
  }
  margin-top: 20px;
  margin-bottom: 20px;
}

.social_icons {
  right: 0;
  @media (max-width: 1200px) {
    top: 0;
    right: auto;
  }
  @media (max-width: 768px) {
    top: 5px;
  }
}

.footer_bottom {
  position: relative;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.footer_bottom_heading {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  text-align: center;
}

@keyframes ripple1 {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  33% {
    transform: scale(0.95);
    opacity: 0.05;
  }
  66% {
    transform: scale(1);
    opacity: 0.1;
  }
  100% {
    transform: scale(1.05);
    opacity: 0;
  }
}
