.carousel-indicators [data-bs-target] {
  width: 10px !important;
  height: 10px !important;
  opacity: 1 !important;
  background: white !important;
  border-radius: 10px;
  border-top: 0px !important;
  border-bottom: 0px !important;
}

.carousel-indicators .active {
  background: black !important;
  opacity: 1;
}

.arrow {
  font-size: 60px !important;
  color: white;
  font-weight: bold;
  opacity: 1 !important;
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: transparent !important;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 1 !important;
  transition: opacity 0.15s ease;
}
