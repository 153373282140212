@import "../../common.scss";

.banner_sec {
  padding: 160px 0 120px;
  min-height: 100%;
  overflow: hidden;
  @media (max-width: 991px) {
    padding-top: 120px;
  }
  @media (max-width: 575px) {
    padding-top: 90px;
  }
}

.contact_details {
  padding: 20px 20px 40px;
  border-radius: 20px;
  h2 {
    font-weight: 600;
    padding-top: 22px;
    font-size: 18px;
    text-decoration: none;
    color: #000000;
    a {
      text-decoration: none;
      color: #000000;
    }
  }
  p {
    font-size: 12px;
  }
}

.contact_snapchat {
  background-color: #fdff9d;
}

.contact_twitter {
  background-color: #76bff8;
}

.contact_youtube {
  background-color: #ff9d9d;
}

.contact_facebook {
  background-color: #879ccd;
}

.contact_instagram {
  background-color: #ffbdf8;
}

.contact_email {
  background-color: #6dd985;
  padding: 20px 20px 40px;
  border-radius: 20px;
  .contact_email_heading {
    padding-top: 22px;
    font-size: 18px;
    text-decoration: none;
    color: #000000;
  }
  .contact_email_link {
    font-size: 14;
    margin-left: 7px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #ffffff;
  }
  p {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
  }
}
